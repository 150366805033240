@media only screen and (max-width: 950px) {
  .contact-img {
    max-width: 90% !important;
    width: auto !important;
    height: auto !important; }
  #rootxnet-content {
    width: 100% !important; }
  .section_service {
    width: 95% !important; }
  .rootxnet-navbar {
    position: relative !important; }
  .rootxnet-navbar-content {
    width: 90% !important; }
  .rootxnet-content-fixed-margin {
    margin-top: 10px !important; }
  nav.rootxnet-navbar .rootxnet-navbar-menu ul {
    display: none;

    li {
      margin-top: 25px; } }
  .rootxnet_rwd_menu {
    display: flex !important;
    flex-direction: column;
    justify-content: center; }
  .rootxnet-navbar-logo {
    flex: 2; }
  .rootxnet_rwd_menu {
    flex: 1; }
  .rootxnet_rwd_menu ul {
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      padding-bottom: 15px;
      margin-top: 20px; } }
  #hidden_menu {
    background: orange;
    padding: 15px 5px 15px 5px;
    text-align: center;
    margin-top: 1px;

    li {
      margin-top: 15px;
      cursor: pointer; }

    #hidden_services_menu {
      display: none;

      .hidden_submenu {
        margin-top: 1px;
        background: #eca012; } }

    ul {
      margin: 0;
      padding: 0; } }
  .card_wrapper {
    margin-top: 15px; }
  .rwd_left {
    flex-direction: column; }
  .rwd_right {
    flex-direction: column-reverse; }
  .section_service .service_left, .section_service .service_right {
    margin: auto;
    width: 90% !important; }
  .section_service .service_left img, .section_service .service_right img {
    max-width: 100%;
    width: 70%;
    height: auto !important;
    margin: auto; }
  #contact .section_service {
    flex-direction: column; }
  .footer-content-center {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      span {
        display: flex;
        flex-direction: column;
        margin: auto; } }

    ul {
      margin: auto;
      padding: 0;

      li {
        float: left;
        margin-left: 10px; } } }
  .main_img {
    width: 98% !important; } }





