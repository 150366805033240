@charset "utf-8";

@import "directives";
@import "rwd";

.rootxnet_rwd_menu, #hidden_menu {
  display: none; }

.container {
  max-width: 1124px;
  margin: auto; }

.headline-center {
  text-align: center;
  margin: 1em 0;
  font-size: 2rem; }

.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.tile {
  width: 30%;
  min-width: 300px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.18);
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    margin: auto; } }

.tile-image {
  height: 220px;
  overflow-y: hidden;
  cursor: pointer;
  position: relative;

  > img {
    width: 100%; } }

.tile-body {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1; }

.tile {
  h2 {
    text-align: center;
    margin-bottom: 10px; }

  p {
    text-align: center;
    font-size: 14pt;
    flex-grow: 1;
    font-family: proxima-nova, sans-serif !important;
    font-weight: 300; } }

.play-btn {
  box-sizing: border-box;
  display: block;
  width: 80px;
  height: 80px;
  line-height: 20px;
  border: 6px solid #fff;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  justify-content: center;
  outline: none !important;

  i {
    margin-left: 5px; } }

button, .rootxnet_rwd_menu {
  cursor: pointer; }

/* rootxnet global styles */

a {
  text-decoration: none !important;
  color: #504538 !important; }

ul {
  list-style-type: none;
  margin-top: 1em !important;
  margin-bottom: 1em !important; }

textarea {
  resize: vertical;
  overflow: auto; }

body {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  font-family: Inconsolata !important;
  margin: 0;
  line-height: 1.42857143 !important; }

body#careers {
  background-color: #f5f5f5; }

.border-breaker-top, .border-breaker-down, .border-breaker-right {
  position: absolute;
  background: red; }

.border-breaker-top {
  height: 2px;
  width: 8px;
  top: -1px;
  left: 10%; }

.border-breaker-down {
  height: 2px;
  width: 8px;
  bottom: -1px;
  left: 30%; }

.border-breaker-right {
  height: 7px;
  width: 2px;
  top: 8px;
  right: -1px; }

.rootxnet_main_label {
  font-size: 25px;
  font-weight: bold; }

.span_image_menu {
  background: url("/static/images/icon1menu.png") no-repeat;
  height: 50px;
  width: 50px;
  background-size: 100%; }

.span_image {
  background: url("/static/images/icon1.png") no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100%; }

.text_padding {
  line-height: 35px; }

.contact-img {
  height: 300px; }

/* end - rootxnet global styles */
/* rootxnet reuse components */

.margin_bottom_25 {
  margin-bottom: 25px; }

.center_text {
  text-align: center; }

.rootxnet-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.rootxnet-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }

.rootxnet-element-color-orange {
  background: #fbaf3b; }

.rootxnet-element-color-light-orange {
  background: #fdf5e8; }

.rootxnet-element-color-white {
  background: white; }

.rootxnet-element-color-black {
  background: black; }

.bold_text {
  font-weight: bold; }

.rootxnet-button {
  border-radius: 10px;
  padding: 8px 25px 8px 25px;
  position: relative;
  border: 0.9px solid #08385f;
  font-size: 14pt !important; }

.contact-form-submit-btn {
  background: #ffe2b170;
  font-size: 12pt;
  outline: none;

  &:hover {
    background: #ffe2b1; } }

.rootxnet-button-dark-orange {
  background: #f1e8db;

  &:hover {
    background: #e8d7bd; } }

.rootxnet-button-blue {
  background: #5099ee2e; }

.rootxnet-button-orange {
  background: #faaf3a30; }

.rootxnet-button-blue:hover {
  background: #5099ee54 !important; }

.rootxnet-button-orange:hover {
  background: #faaf3a4d !important; }

.rootxnet-button-strong-orange {
  font-size: 11pt;
  color: black;
  background-color: rgb(255, 227, 177);

  &:hover {
    background-color: rgb(255, 210, 131) !important; } }

/* end - reuse components */

nav.rootxnet-navbar {
  width: 100%;
  height: 95px;
  background: #000000;
  top: 0;
  z-index: 9999;

  .rootxnet-navbar-content {
    margin: 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .rootxnet-navbar-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 25px; }

    .rootxnet-navbar-menu {
      font-size: 13pt;
      text-transform: uppercase;

      ul {
        height: 100%;
        display: flex;
        align-items: center;

        li {
          float: left;
          padding: 5px 10px 5px 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          &.submenu {
            position: relative;

            &:hover ul.bartek, &:active ul.bartek {
              display: block; }

            ul.bartek {
              display: none;
              position: absolute;
              height: auto;
              background: #232323;
              top: 45px;
              left: -20px;
              padding: 0;
              margin: 0;
              width: 180px;
              border-radius: 5px;
              z-index: 1;

              li {
                width: 100%;
                text-align: center;
                margin: 0;
                padding: 10px 0 10px 0;

                a {
                  padding: 0; } } } } }

        a {
          border-radius: 10px;
          padding: 6px 15px 6px 15px;
          position: relative;
          color: #c5a379 !important;

          &.active {
            border: 0.9px solid #a68357 !important; }

          &:hover {
            background: #231f1a;
            cursor: pointer; } } } } } }

#rootxnet-content {
  width: 70%;
  margin: 0 auto;

  section {
    margin-top: 25px;
    min-height: 50px; } }

#rootxnet-content-full {
  width: 100%;

  section {
    margin-top: 25px;
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;

    .section_service {
      width: 70%;
      background: #f3f3f3;
      border-radius: 10px; } } }

.card_wrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #dadada;
  border-radius: 10px;
  margin-right: 15px;
  padding: 15px 15px 15px 15px;
  margin-top: 15px;

  div {
    display: flex;
    justify-content: center;
    text-align: center; }

  .card_header, .card_content {
    padding-top: 15px; }

  .card_button {
    padding-top: 25px; }

  .card_content {
    min-height: 90px; }

  .card_header {
    font-size: 20px; }

  .important_service {
    font-size: 26px;
    color: black !important; }

  .card_icon {
    height: 50px;

    img {
      width: 50px;
      height: 100%; } }

  .card_button span {
    border-radius: 10px;
    padding: 8px 25px 8px 25px;
    position: relative;
    border: 0.9px solid #08385f; } }

.card_wrapper_important {
  background: #fdf7ed;

  .card_header {
    color: #bf8427; }

  .card_button a {
    color: #08385f; } }

.rootxnet-footer {
  margin-top: auto;
  padding-top: 30px;
  width: 100%;

  > div {
    padding: 5px; }

  .rootxnet-row-center {
    background: black;
    color: wheat;
    font-size: 13px; }

  .rootxnet-footer-content {
    background: #fbaf3b;
    font-size: 12pt;

    .footer-content-center {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 70%;

      ul {
        text-align: center; } } } }

/* rootxnet services */

.section_service {
  display: flex;

  .service_left, .service_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px; }

  .service_left img, .service_right img {
    max-width: 100%; }

  .service_left {
    width: 25%; }

  .service_right {
    flex: 2;
    line-height: 28px; } }

.pseudo_code_editor {
  background: white;
  border: 0.5px solid silver;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;

  .pseudo_code_editor_comment {
    opacity: 0.5;
    font-weight: normal; }

  ul li {
    list-style-type: disc;
    padding: 5px 5px 5px 5px;
    border-bottom: 0.5px solid #d8d8d8; }

  .code_header {
    list-style-type: none;
    border-bottom: none;
    font-size: 16px;
    margin-bottom: 25px;
    background: #f5f7f9;
    padding: 10px; } }

.rootxnet_service_main_header {
  width: 60%;
  margin: auto;
  text-align: center; }

/* contact */

.contact_container {
  border: 0.5px solid silver;
  border-radius: 10px;
  padding: 5px 20px 15px 20px;
  background: #589fd10d;

  div {
    margin-top: 15px; }

  a {
    line-height: 25px; }

  .contact_icon {
    width: 30px;
    text-align: center;
    font-size: 25px; } }

.contact_form {
  .form-group {
    display: flex; }

  input, textarea {
    width: 100%;
    margin: 5px 0 15px 0;
    padding: 7px 12px;
    border: 0.5px solid silver;
    border-radius: 10px;
    font-family: Inconsolata;
    font-size: 13pt;
    outline: none; }

  input::placeholder, textarea::placeholder {
    color: #b7b7b7; } }

.contact_section_service {
  background: #fdfdfd;
  padding: 10px;
  border: 0.5px solid silver;
  border-radius: 10px; }

.lukas_dzik_styles {
  padding: 15px;
  margin-top: 25px;
  float: left; }

/* Modal styles */

.modal-dialog {
  max-width: 500px !important;
  margin: 10em auto !important; }

.modal-content {
  border-radius: 15px !important; }

button.close {
  background: white !important;
  padding: 0 5px !important;
  border-radius: 50% !important;
  outline: none !important; }

.modal-body {
  position: relative;
  padding: 15px 0 !important;
  height: 600px; }

.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1; }

.team-member {
  width: calc(33% - 40px);
  text-align: center;
  margin: 20px;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%; }

  h2 {
    margin-bottom: 0;
    margin-top: 10px; }

  h4 {
    margin: 0;
    color: gray; }

  p {
    margin-top: 12px;
    font-family: proxima-nova, sans-serif !important;
    font-weight: 300;
    font-size: 13pt; } }

/*# sourceMappingURL=main.css.map */

.bartek li {
  &:hover, a:hover {
    background-color: #171311 !important;
    border-radius: 5px; } }

.get_in_touch_container {
  display: flex;
  align-items: center;

  a {
    margin-left: 10px; } }

#hidden_menu {
  background: orange;
  padding: 15px 5px 15px 5px;
  text-align: center;

  li {
    margin-top: 15px;
    cursor: pointer;
    font-size: 15pt; }

  #hidden_services_menu {
    display: none;

    .hidden_submenu {
      margin-top: 1px;
      background: #e29609;
      padding: 5px 0;
      border-radius: 5px; } }

  ul {
    margin: 0;
    padding: 0; } }

.hamburger-icon {
  display: none;
  padding-left: 2em;

  i {
    font-size: 16pt;
    color: #f9d295ad; } }

@media only screen and (max-width: 1141px) {
  .hamburger-icon {
    display: flex;
    align-items: center; }

  nav.rootxnet-navbar .rootxnet-navbar-content .rootxnet-navbar-menu {
    display: none; } }

@media only screen and (max-width: 767px) {
  .tile {
    width: 80%; } }

@media only screen and (max-width: 752px) {
  .team-member {
    width: calc(100% - 40px); } }

@media only screen and (max-width: 671px) {
  .card_wrapper {
    margin-right: 0; }

  .section_service {
    .service_left img, .service_right img {
      width: 100%; } }

  .how-we-work-section {
    flex-direction: column !important;
    text-align: center;

    img {
      margin: auto !important; } } }

@media only screen and (max-width: 550px) {
  p {
    text-align: center; }

  .tile, .rootxnet_service_main_header {
    width: 100%; } }

@media only screen and (max-width: 487px) {
  .get_in_touch_container {
    flex-direction: column;

    a {
      margin-left: 0;
      margin-top: 10px; } } }

section h1.section-header {
  text-align: center;
  font-size: 21pt; }

#about-us {
  p {
    font-weight: 300;
    font-family: proxima-nova, sans-serif !important;
    letter-spacing: 0.3px;
    font-size: 16.5px; } }

#what-we-use {
  margin-top: 20px;
  padding: 10px 20px 20px;

  .tech-icon-list {
    display: block;
    font-size: 5em;
    line-height: 1em;
    text-align: center; }

  .tech-list {
    margin-top: 3em !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      display: inline-flex;
      text-align: center;
      font-weight: bold;
      font-size: 1em;
      line-height: 1em;
      margin-right: 50px;
      padding-bottom: 15px;

      a {
        color: #646464 !important; } } } }

#they-trusted-us .client-list {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    display: inline;
    width: 100px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1em;
    margin-right: 50px;
    padding-bottom: 15px;

    img {
      max-width: 140px;
      max-height: 80px;
      vertical-align: middle; } } }

// How We Work section
#how-we-work {
  padding: 20px 20%;
  margin-top: 2em !important;
  max-width: 900px;
  margin: auto;

  p {
    font-weight: 300;
    font-family: proxima-nova, sans-serif !important;
    letter-spacing: 0.3px;
    font-size: 16.5px; }

  h1 {
    margin: 20px 0; } }

.how-we-work-section {
  display: flex;

  &:not(:first-of-type) {
    margin: 1.5em 0; }

  &:nth-child(odd) {
    flex-direction: row-reverse; }

  img {
    max-width: 150px;
    max-height: 150px;
    display: flex;
    margin: auto; }

  &:nth-child(odd) img {
    margin-left: 3em; }

  &:nth-child(even) img {
    margin-right: 3em; } }

.how-we-work-section-body {
  font-size: 13pt;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 17pt;
    margin-bottom: 0 !important; } }

.tech-logos-large, .tech-logos-small {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; }

.tech-logos-large {
  margin: 3em 0 2em; }

.tech-logo {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin: 0 1em 10px;
  height: 100px;
  > {}
  h1, h3 {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0 0 0; } }

.tech-logos-large img {
  width: 100px;
  border-radius: 10px;
  margin: auto; }

.tech-logos-small {
  justify-content: center;
  i {
    font-size: 4em;
    margin: auto; }

  img {
    max-width: 150px;
    margin: auto;
    max-height: 70px; } }

#conference-timeline {
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto !important;

  .conference-center-line {
    position: absolute;
    width: 2px;
    height: 95%;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background: #F3802F;
    z-index: -1; }

  .conference-timeline-content {
    padding-top: 35px;
    padding-bottom: 0; } }

.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;

  .content-left-container {
    float: left;
    text-align: right; }

  .content-right-container {
    float: right;
    text-align: left; }

  .content-left, .content-right {
    position: relative;
    width: auto;
    padding: 37px 25px; }

  .content-left {
    padding-left: 0; }

  .content-right {
    padding-right: 0; }

  .content-left-container, .content-right-container {
    max-width: 44%;
    width: 100%; }

  .meta-date {
    position: absolute;
    top: 0;
    left: 50%;
    width: 90px;
    height: 90px;
    margin-left: -48px;
    color: white;
    border-radius: 100%;
    background: white;
    border: 2px solid #F3802F;
    text-align: center;
    display: flex;

    img {
      width: 70px;
      margin: auto;
      border-radius: 50%; } } }

.timeline__header {
  font-size: 13pt;
  text-transform: uppercase;
  line-height: initial;
  font-weight: bold; }

.title__underline {
  width: 120px;
  border-top: 2px solid #F3802F; }

.timeline-article {
  .content-left-container .title__underline {
    margin-right: 0;
    margin-top: 5px; }

  .content-right-container .title__underline {
    margin-left: 0;
    margin-top: 5px;
    width: 70px; } }

.office-photo {
  display: flex;
  width: 100%;
  border-radius: 5px; }

#careers {
  .job-section {
    .job-section-header {
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline; }
    ul {
      li {
        list-style: disc; } } } }

@media only screen and (max-width: 1320px) {
  .section_service .service_left {
    width: 36%; } }

@media only screen and (max-width: 830px) {
  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px; }

  .timeline-article {
    .content-left-container, .content-right-container {
      max-width: 100%;
      width: auto;
      float: none;
      margin-left: 90px;
      min-height: 53px; }

    .content-left-container {
      margin-bottom: 20px; }

    .content-left, .content-right {
      padding: 10px 5px;
      min-height: 65px; }

    .meta-date {
      margin-left: 0;
      left: 0;
      width: 75px;
      height: 75px;

      img {
        width: 60px; } }

    p {
      text-align: left; } } }

@media screen and (max-width: 768px) {
  .timeline-article .content-left-container {
    .title__underline {
      margin-right: initial;
      margin-left: 0; }

    text-align: left; } }

.centered-headline {
  font-size: 15pt;
  font-family: proxima-nova, sans-serif !important;
  font-weight: 300; }

@media (hover: hover) and (pointer: fine) {
  nav.rootxnet-navbar .rootxnet-navbar-content .rootxnet-navbar-menu ul li.submenu:hover ul.bartek {
    display: block; } }
